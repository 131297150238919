import React from 'react';
import { useIntl } from 'react-intl';
import { BlockDataForm } from '@plone/volto/components';
import { Schema } from './Schema';
import config from '@plone/volto/registry';

const Data = (props) => {
  const { block, data, onChangeBlock } = props;
  const intl = useIntl();

  const schema = Schema({ ...props, intl });

  const applySchemaEnhancer = (schema) => {
    const variations = config.blocks?.blocksConfig['benefits']?.variations;

    const schemaExtender = variations?.[data.variation]?.['schemaExtenderItem'];

    if (schemaExtender) {
      return schemaExtender(schema, props, intl);
    } else {
      return schema;
    }
  };

  return (
    <BlockDataForm
      schema={applySchemaEnhancer(schema)}
      title={schema.title}
      onChangeField={(id, value) => {
        onChangeBlock(block, {
          ...data,
          [id]: value,
        });
      }}
      formData={data}
      fieldIndex={data.index}
    />
  );
};

export default Data;
