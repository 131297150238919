/**
 * PostView view component.
 * @module components/View/Certificate
 */

import React from 'react';
import PropTypes from 'prop-types';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import downloadIcon from '@plone/volto/icons/download.svg';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

/**
 * CertificateView view component class.
 * @function CertificateView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const CertificateView = ({ content, moment: momentlib }) => {
  const moment = momentlib.default;
  moment.locale('pt-br');
  const mStart = moment(content.start);
  const mEnd = moment(content.end);
  const mCreation = moment(content.created);
  const sameDay = mStart.isSame(mEnd, 'day');
  const startDate = mStart.format('L');
  const endDate = mEnd.format('L');
  const creation = mCreation.format('L');
  return (
    <Container id="page-document" className="view-wrapper certificate-view">
      <h1 className="documentFirstHeading">
        Certificado de Participação em Treinamento
      </h1>
      <h2>Dados do Certificado</h2>
      <div className="certificateCheckerWrapper">
        <div className="certificateChecker">
          <div className="training-info">
            <h3>Treinamento</h3>
            <table>
              <tbody>
                <tr>
                  <th>Treinamento</th>
                  <td>{content.training_title}</td>
                </tr>
                <tr>
                  {sameDay ? (
                    <>
                      <th>Data</th>
                      <td>{startDate}</td>
                    </>
                  ) : (
                    <>
                      <th>Datas</th>
                      <td>
                        De {startDate} a {endDate}
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                  <th>Carga Horária</th>
                  <td>{content.duration} horas</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="student-info">
            <h3>Participante</h3>
            <table>
              <tbody>
                <tr>
                  <th>Nome</th>
                  <td>{content.fullname}</td>
                </tr>
                {content.client && (
                  <tr>
                    <th>Organização</th>
                    <td>{content.client}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="certificateChecker">
          <div className="certificate-info">
            <h3>Dados do Arquivo</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Emissão</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{content.id}</td>
                  <td>{creation}</td>
                  <td>
                    {content.file?.download && (
                      <a href={flattenToAppURL(content.file.download)}>
                        <Icon name={downloadIcon} />
                      </a>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
CertificateView.propTypes = {
  content: PropTypes.shape({
    fullname: PropTypes.string,
    training_title: PropTypes.string,
  }).isRequired,
};

export default injectLazyLibs(['moment'])(CertificateView);
