// Blocks
import SimpleTeaserEditBlock from './components/Blocks/SimpleTeaser/Edit';
import SimpleTeaserViewBlock from './components/Blocks/SimpleTeaser/View';

// View
import CertificateView from './components/View/Certificate';

// Icons
import sliderSVG from '@plone/volto/icons/slider.svg';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
    apiExpanders: [
      ...config.settings.apiExpanders,
      {
        match: '',
        GET_CONTENT: [
          'breadcrumbs',
          'navigation',
          'actions',
          'authors',
          'types',
        ],
        querystring: {
          'expand.navigation.depth': 2,
        },
      },
    ],
    image_crop_aspect_ratios: [
      {
        label: '16:9',
        ratio: 16 / 9,
      },
      {
        label: '4:3',
        ratio: 4 / 3,
      },
      {
        label: '1:1',
        ratio: 1,
      },
    ],
    socialNetworks: [
      {
        id: 'twitter',
        href: 'https://twitter.com/simplesconsult',
      },
      {
        id: 'github',
        href: 'https://github.com/simplesconsultoria',
      },
      {
        id: 'linkedin',
        href: 'https://www.linkedin.com/company/simples-consultoria',
      },
      {
        id: 'facebook',
        href: 'https://www.facebook.com/simplesconsultoria',
      },
    ],
  };
  // Views
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Certificate: CertificateView,
  };
  // Blocks
  config.blocks.blocksConfig.simpleTeaser = {
    id: 'simpleTeaser',
    title: 'Simple Teaser',
    icon: sliderSVG,
    group: 'teasers',
    view: SimpleTeaserViewBlock,
    edit: SimpleTeaserEditBlock,
    restricted: true,
    mostUsed: true,
    sidebarTab: 1,
    security: {
      addPermission: [],
      view: [],
    },
  };

  // Check for @kitconcept/volto-blocks-grid
  const gridBlock = config.blocks.blocksConfig.__grid;
  if (gridBlock !== undefined) {
    config.blocks.blocksConfig.__grid.gridAllowedBlocks = [
      ...gridBlock.gridAllowedBlocks,
      'simpleTeaser',
      'video',
      'map',
    ];
  }

  return config;
};

export default applyConfig;
