import sliderIcon from '@plone/volto/icons/slider.svg';
import globeIcon from '@plone/volto/icons/globe.svg';
import contentIcon from '@plone/volto/icons/content.svg';

import codeIcon from 'volto-simplesconsultoria/icons/teaser/file-code.svg';
import trainingIcon from 'volto-simplesconsultoria/icons/teaser/graduation-cap.svg';
import supportIcon from 'volto-simplesconsultoria/icons/teaser/headset.svg';
import laptopIcon from 'volto-simplesconsultoria/icons/teaser/laptop.svg';
import securityIcon from 'volto-simplesconsultoria/icons/teaser/lock.svg';
import microphoneIcon from 'volto-simplesconsultoria/icons/teaser/microphone.svg';

const TeaserIcons = {
  content: contentIcon,
  globe: globeIcon,
  settings: sliderIcon,
  codeIcon: codeIcon,
  trainingIcon: trainingIcon,
  supportIcon: supportIcon,
  laptopIcon: laptopIcon,
  securityIcon: securityIcon,
  microphoneIcon: microphoneIcon,
};

export default TeaserIcons;
