import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  icon: {
    id: 'Icon',
    defaultMessage: 'Icon',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
});

export const Schema = (props) => {
  const { intl } = props;

  return {
    title: intl.formatMessage(messages.item),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['icon', 'title', 'description', 'href'],
      },
    ],

    properties: {
      icon: {
        title: intl.formatMessage(messages.icon),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['content', 'Content'],
          ['globe', 'Globe'],
          ['settings', 'Settings'],
          ['codeIcon', 'Code'],
          ['trainingIcon', 'Training'],
          ['supportIcon', 'Support'],
          ['laptopIcon', 'Laptop'],
          ['securityIcon', 'Security'],
          ['microphoneIcon', 'Microphone'],
        ],
      },
      title: {
        title: intl.formatMessage(messages.title),
      },
      description: {
        title: intl.formatMessage(messages.description),
        widget: 'textarea',
      },
      href: {
        title: intl.formatMessage(messages.Source),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description'],
        allowExternals: false,
      },
    },
    required: ['icon', 'title', 'description', 'href'],
  };
};
