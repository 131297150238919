import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { Icon, UniversalLink } from '@plone/volto/components';
import { Header } from 'semantic-ui-react';
import TeaserIcons from './TeaserIcons';

const messages = defineMessages({
  PleaseEditContent: {
    id: 'Please edit this element',
    defaultMessage: 'Please edit this element',
  },
});

const DefaultBody = (props) => {
  const { data, isEditMode } = props;
  const intl = useIntl();
  const icon = TeaserIcons[data.icon];
  const href = data.href?.[0];

  return (
    <>
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseEditContent)}</p>
          </div>
        </Message>
      )}
      {href && isEditMode && (
        <div className="grid-teaser-item top benefit">
          <Header as="h2" icon textAlign="left" className={'header-benefit'}>
            <Icon name={icon} size={'60px'} />
            <Header.Content size={'medium'}>{data.title}</Header.Content>
          </Header>
          <p className={'body-benefit'}>{data.description}</p>
        </div>
      )}
      {href && !isEditMode && (
        <div className="grid-teaser-item top benefit">
          <UniversalLink href={href['@id']}>
            <Icon name={icon} size={'60px'} />
            <Header as="h2" icon textAlign="left" className={'header-benefit'}>
              <Header.Content size={'medium'}>{data.title}</Header.Content>
            </Header>
            <p className={'body-benefit'}>{data.description}</p>
          </UniversalLink>
        </div>
      )}
    </>
  );
};

DefaultBody.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default DefaultBody;
